import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const LocalBusinessSchema = () => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImg: wpMediaItem(title: { eq: "Cranfield IT Response Car" }) {
					localFile {
						publicURL
					}
				}
				reviews {
					reviewCount
				}
				schemaLogo: file(relativePath: { eq: "icon.png" }) {
					publicURL
				}
			}
		`
	);

	const reviewCount = data.reviews.reviewCount;
	const localBusinessSchema = {
		"@context": "https://schema.org",
		"@type": "LocalBusiness",
		"@id": "https://cranfielditsolutions.co.uk",
		name: "Cranfield IT Solutions",
		legalName: "Cranfield IT Solutions",
		description:
			"Managed IT Service Provider, offering IT services and solutions on straightforward, convenient monthly contracts.",
		url: "https://cranfielditsolutions.co.uk",
		priceRange: "$$",
		areaServed: [
			{
				"@type": "City",
				name: "London",
				sameAs: "https://en.wikipedia.org/wiki/London",
			},
			{
				"@type": "Place",
				name: "Sussex",
				sameAs: "https://en.wikipedia.org/wiki/Sussex",
			},
		],
		email: "info@cranfielditsolutions.co.uk",
		currenciesAccepted: "GBP",
		numberOfEmployees: "1",
		location: {
			"@type": "City",
			name: "London",
			sameAs: "https://en.wikipedia.org/wiki/London",
		},
		foundingLocation: {
			"@type": "City",
			name: "London",
			sameAs: "https://en.wikipedia.org/wiki/London",
		},
		slogan:
			"Managed IT Service Provider, offering IT services and solutions on straightforward, convenient monthly contracts.",
		telephone: "+442033227261",
		address: {
			"@type": "PostalAddress",
			addressLocality: "London",
			addressRegion: "London",
			postalCode: "W1T 6EB",
			streetAddress: "4th Floor, Silverstream House, 45 Fitzroy St",
			addressCountry: "United Kingdom",
		},
		geo: {
			"@type": "GeoCoordinates",
			latitude: "51.523856",
			longitude: "-0.140502",
		},
		hasMap: "https://goo.gl/maps/ZDaPoMmWSQ8pAQkT8",
		openingHours: "Mo,Tu,We,Th,Fr 09:00-17:00",
		openingHoursSpecification: [
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Monday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Tuesday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Wednesday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Thursday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Friday",
				opens: "09:00",
			},
		],
		founder: {
			"@type": "Person",
			name: "Matt Cranfield",
			id: "https://cranfielditsolutions.co.uk/about-us#matt",
			url: "https://cranfielditsolutions.co.uk/about-us",
			description:
				"Matt Cranfield has over a decade and a half of experience in the Information Technology field. He has worked in IT repair shops, managed service providers, technology sales, and as a IT specialist within Manufacturing.",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			bestRating: "5",
			worstRating: "1",
			ratingValue: "4.9",
			reviewCount: `${reviewCount}`,
			url: "https://cranfielditsolutions.co.uk",
		},
		contactPoint: {
			"@type": "ContactPoint",
			"@id": "https://cranfielditsolutions.co.uk/contact-us",
			url: "https://cranfielditsolutions.co.uk/contact-us",
			// "image": `${data.schemaLogo.publicURL}`,
			name: "Cranfield IT Solutions",
			description:
				"Managed IT Service Provider, offering IT services and solutions on straightforward, convenient monthly contracts.",
			telephone: "+442033227261",
			contactType: "Contact Page",
			availableLanguage: "en",
			areaServed: [
				{
					"@type": "City",
					name: "London",
					sameAs: "https://en.wikipedia.org/wiki/London",
				},
				{
					"@type": "Place",
					name: "Sussex",
					sameAs: "https://en.wikipedia.org/wiki/Sussex",
				},
			],
		},
		logo: `https://cranfield-it-solutions.rjmdigital.net/wp-content/uploads/2023/06/Logo.png`,
		image: `https://cranfield-it-solutions.rjmdigital.net/wp-content/uploads/2023/06/Cranfield-IT-Response-Car-scaled.jpg`,
		sameAs: [],
	};

	return (
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify(localBusinessSchema)}
			</script>
		</Helmet>
	);
};

export default LocalBusinessSchema;
